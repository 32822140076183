import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { getPartner } from '../../service/partners/selector';
import PartnerContainer from '../PartnerContainer';
import { deepEqual } from '../../utils';
import Config from '../../Components/Config';
import { getData } from '../../service/partnersData/actions';
import ExternalIdAssignation from '../../Components/ExternalDataAssignation';
import { Button } from '@material-ui/core';
import ProductsSelector from '../../Components/ProductsSelector';
import { getProductsList } from '../../Components/ProductsSelector/Components/ProductsSelectorModal/selector';
import { bindActionCreators } from 'redux';
import { setSelected } from '../../Components/ProductsSelector/service/products/actions';
import SelectVariableServer from '../../../../Components/SelectVariableServer';
import Alert from '../../../../Components/Alert';
import {
	ManageAccountingCategoriesLink,
	ManageServicesLink,
	UnderstandServicesLink,
	IntegrationPOSLink,
	ManageOutletsLink,
} from './documentationLinks';

class Mews extends Component {
	shouldComponentUpdate(nextProps) {
		if (
			deepEqual(this.props.partnerData, nextProps.partnerData) &&
			deepEqual(this.props.externalData, nextProps.externalData) &&
			deepEqual(this.props.products, nextProps.products)
		) {
			return false;
		}

		return true;
	}

	componentDidMount() {
		if (this.props.partnerData.length === 0) {
			this.props.getPartnerData();
		}
	}

	validProductSelector = products => {
		let externalDataProduct = this.props.externalData.filter(
			e => e.model === 'id_product'
		);

		const idMenu = Object.keys(products)[0] || null;
		let selected = idMenu ? products[idMenu] : [];

		const firstFoodMews = this.props.partnerData.filter(
			a => a.type === 'FoodAndBeverage'
		)[0];

		if (firstFoodMews) {
			const newProducts = selected.reduce((acc, id) => {
				if (!externalDataProduct.find(e => e.id_internal == id)) {
					acc.push({
						partner_prefix: this.props.partner.prefix,
						model: 'id_product',
						data_type: 'string',
						path: 'id',
						data: id,
						id_internal: id,
						id_external: firstFoodMews.id,
						data_external: firstFoodMews.id,
						data_type_external: 'string',
						path_external: 'id',
						active: 1,
					});
				}
				return acc;
			}, []);

			const deletedProducts = externalDataProduct
				.filter(e => e.active && !selected.includes(e.id_internal))
				.map(e => ({ ...e, active: 0 }));

			const reActivateProducts = externalDataProduct
				.filter(e => !e.active && selected.includes(e.id_internal))
				.map(e => ({ ...e, active: 1 }));

			for (let product of [
				...newProducts,
				...deletedProducts,
				...reActivateProducts,
			]) {
				this.props.patchExternalData(product);
			}
		}
	};

	setSelectedItems = _ => {
		let selectedProducts = this.props.externalData
			.filter(e => e.model === 'id_product' && e.active)
			.map(e => e.id_internal);
		let selectedProductsObject = selectedProducts.reduce((acc, id) => {
			let prod = this.props.products[id];
			if (prod) {
				if (!acc[prod.id_menu]) acc[prod.id_menu] = [];
				acc[prod.id_menu].push(id);
			}
			return acc;
		}, {});
		this.props.setSelectedAssignator(selectedProductsObject);
	};

	render() {
		const { partner, partnerData, products, outlets, services } = this.props;

		const displayDoc =
			!services ||
			(services && services.length === 0) ||
			!outlets ||
			(outlets && outlets.length === 0) ||
			!partnerData ||
			(partnerData && partnerData.length === 0);

		return (
			<PartnerContainer
				name={I18n.t(`partner.prefix.${partner.prefix}`)}
				id={partner ? partner.id : null}
				prefix={partner ? partner.prefix : null}>
				<Button
					style={{ margin: 10 }}
					variant="contained"
					color="primary"
					onClick={this.props.getPartnerData}>
					{I18n.t('partner.mewsSystems.refreshData')}
				</Button>

				{displayDoc && (
					<Alert>
						<div className="container-mews-documentation">
							<p>{I18n.t('partner.mewsSystems.documentationDescription')}</p>

							<ManageServicesLink />
							<UnderstandServicesLink />
							<ManageAccountingCategoriesLink />
							<IntegrationPOSLink />
						</div>
					</Alert>
				)}

				{partnerData && partnerData.length ? (
					<>
						<h2>{I18n.t('partner.mewsSystems.settingsDefault')}</h2>
						<div className="container-mews-default-setting">
							<SelectVariableServer
								keyVariable={'outletIdMews'}
								label={I18n.t('partner.mewsSystems.outletInputLabel')}
								list={outlets}
							/>
							<SelectVariableServer
								keyVariable={'serviceIdTokenMews'}
								label={I18n.t('partner.mewsSystems.serviceInputLabel')}
								list={services}
							/>
							<SelectVariableServer
								keyVariable={'defaultPaymentIdMews'}
								label={I18n.t('partner.mewsSystems.paymentInputLabel')}
								list={partnerData.filter(a => a.type === 'Payments')}
							/>
						</div>
						{(!outlets || outlets.length === 0) && (
							<div>
								<ManageOutletsLink />
							</div>
						)}

						<h2>{I18n.t('partner.mewsSystems.settingsPayments')}</h2>
						<ExternalIdAssignation
							prefix={partner.prefix}
							type="paymentType"
							externals={partnerData.filter(a => a.type === 'Payments')}
							internals={[
								...this.props.binding['paymentType'],
								{
									id: 'customer_account',
									name: I18n.t('binding.customer_account'),
								},
							]}
						/>

						<h2>{I18n.t('partner.mewsSystems.settingsProductTypes')}</h2>
						<ExternalIdAssignation
							prefix={partner.prefix}
							type="productType"
							externals={partnerData.filter(
								a => a.type && a.type !== 'Payments'
							)}
						/>

						<h2>{I18n.t('partner.mewsSystems.settingsProduct')}</h2>
						<ProductsSelector
							onValid={this.validProductSelector}
							setSelectedItems={this.setSelectedItems}
							menuSelectorDisabled={true}
						/>
						<ExternalIdAssignation
							prefix={partner.prefix}
							type="productId"
							externals={partnerData.filter(
								a => a.type && a.type !== 'Payments'
							)}
							internals={this.props.externalData
								.filter(e => e.model === 'id_product' && e.active)
								.map(e => ({
									id: e.id_internal,
									name: this.props.productLoaded
										? products[e.id_internal] && products[e.id_internal].name
										: I18n.t('general.loading'),
								}))}
						/>

						<h2>{I18n.t('partner.mewsSystems.settingsFloorOutlet')}</h2>
						<ExternalIdAssignation
							prefix={partner.prefix}
							type="floorAll"
							externals={outlets}
							type_2="floorAllService"
							externals_2={services}
						/>
					</>
				) : (
					''
				)}

				<Config prefix_partner={partner.prefix || ''} />
			</PartnerContainer>
		);
	}
}

const mapStateToProps = (state, props) => {
	const partner = getPartner(state, props);
	const data = state.partnersData['mewsSystems'];
	const externalData = state.externalData['mewsSystems'] || [];
	let products = getProductsList(state);
	let _productsFlat = Object.keys(products).reduce((acc, id_menu) => {
		for (let id_product in products[id_menu]) {
			acc[id_product] = products[id_menu][id_product];
		}
		return acc;
	}, {});
	const binding = state.binding;

	return {
		partner,
		partnerData:
			data && data.accountCategories
				? data.accountCategories.map(e => ({
						id: e.Id,
						name: e.Name,
						type: e.Classification,
				  }))
				: [],
		outlets:
			data && data.outlets
				? data.outlets.map(e => ({
						id: e.Id,
						name: e.Name,
				  }))
				: [],
		services:
			data && data.services
				? data.services.map(e => ({
						id: e.Id,
						name: e.Name,
				  }))
				: [],
		externalData,
		products: _productsFlat,
		productLoaded: state.exports.productsSelector.products.loaded,
		binding,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getPartnerData: _ => dispatch(getData('mewsSystems')),
		patchExternalData: data => {
			dispatch({ type: 'PRE_PATCH_EXTERNAL_DATA', data });
		},
		setSelectedAssignator: bindActionCreators(setSelected, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Mews);
