import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import PriceCategory from '../Binding/Components/PriceCategory';
import Table from '../Binding/Components/Table';
import PaymentType from '../Binding/Components/PaymentType';
import './index.scss';
import Alert from '../../../../Components/Alert';

const AssignatorComponents = {
	priceCategory: PriceCategory,
	floor: Table,
	floorAll: Table,
	floorAllService: Table,
	paymentType: PaymentType,
	productType: PaymentType,
	productId: PaymentType,
};
class ExternalDataAssignation extends Component {
	getModel = type => {
		switch (type) {
			case 'priceCategory':
				return 'price_category';
			case 'floor':
			case 'floorAll':
				return 'floor';
			case 'floorAllService':
				return 'service_id_floor';
			case 'paymentType':
				return 'payment_type';
			case 'productType':
				return 'product_type';
			case 'productId':
				return 'id_product';
			default:
				return null;
		}
	};
	onChange = (id_internal, id_external, type) => {
		let model = this.getModel(type);

		let selected = this.props.externalData.find(
			d => d.model === model && d.id_internal === id_internal
		);

		if (selected) {
			this.props.patchExternalData({
				...selected,
				id_external,
				data_external: id_external,
			});
		} else {
			this.props.patchExternalData({
				partner_prefix: this.props.prefix,
				model,
				data_type: 'string',
				path: 'id',
				data: id_internal,
				id_internal,
				id_external,
				data_external: id_external,
				data_type_external: 'string',
				path_external: 'id',
				active: 1,
			});
		}
	};

	render() {
		let Assignator = AssignatorComponents[this.props.type];

		let model = this.getModel(this.props.type);

		return (
			<div className="externalIdAssignator">
				{(!this.props.internals ||
					this.props.internals.length === 0 ||
					!Assignator ||
					!model) &&
				!this.props.internalsOverided ? (
					<Alert>{I18n.t(`partner.externalData.load_error`)}</Alert>
				) : (
					<React.Fragment>
						<Alert>
							{I18n.t(`partner.externalData.info`, {
								entity: I18n.t(
									`partner.externalData.info_entity.` + this.props.type
								),
							})}
						</Alert>
						<div className="externalIdAssignator-container">
							<div className="externalIdAssignator-head">
								<p> {I18n.t('general.laddition')}</p>
								<p> {' : '}</p>
								<p>{I18n.t(`partner.prefix.${this.props.prefix}`)}</p>
							</div>
							<div className="externalIdAssignator-body">
								{this.props.internals.map(e => {
									let selected = this.props.externalData.find(
										d => d.model === model && d.id_internal == e.id
									);

									let selected_2 = null;
									if (this.props.type_2 && this.props.externals_2) {
										selected_2 = this.props.externalData.find(
											d =>
												d.model === this.getModel(this.props.type_2) &&
												d.id_internal == e.id
										);
									}

									return (
										<div className="externalIdAssignator-row">
											<p>{e.name} </p>
											<div style={{ display: 'flex' }}>
												<Assignator
													list={this.props.externals}
													selected={selected ? selected.id_external : ''}
													onChange={(type, value) => {
														this.onChange(e.id, value, this.props.type);
													}}
													hideLabel={true}
													required={true}
												/>
												{this.props.externals_2 && this.props.type_2 ? (
													<span style={{ marginLeft: 10 }}>
														<Assignator
															list={this.props.externals_2}
															selected={
																selected_2 ? selected_2.id_external : ''
															}
															onChange={(type, value) => {
																this.onChange(e.id, value, this.props.type_2);
															}}
															hideLabel={true}
															required={true}
														/>
													</span>
												) : null}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</React.Fragment>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const { binding, externalData } = state;
	return {
		internals: props.internals || binding[props.type],
		externalData: externalData[props.prefix] || [],
		internalsOverided: !!props.internals,
	};
};

const mdtp = dispatch => ({
	patchExternalData: data => {
		dispatch({ type: 'PRE_PATCH_EXTERNAL_DATA', data });
	},
});

export default connect(mapStateToProps, mdtp)(ExternalDataAssignation);
